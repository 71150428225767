export const DASHBOARD_ROUTE = '/dashboard'

// PURCHASING
export const PURCHASE_ROUTE = '/purchase'
export const ACCOUNT_ADDRESS_ROUTE = PURCHASE_ROUTE + '/account/address'
export const NEW_ORDER_ROUTE = PURCHASE_ROUTE + '/new-order'
export const NEW_CUSTOM_BASKET_ROUTE = PURCHASE_ROUTE + '/new-custom-basket'
export const BASKETS_ROUTE = PURCHASE_ROUTE + '/baskets'

// Address
export const USER_ADDRESS_ROUTE = PURCHASE_ROUTE + '/user/address'
export const USER_ADDRESS_INFO_ROUTE = PURCHASE_ROUTE + '/user/address/info'
export const USER_ADDRESS_MAP_ROUTE = PURCHASE_ROUTE + '/user/address/info/map'
export const EDIT_USER_ADDRESS_INFO_ROUTE = PURCHASE_ROUTE + '/user/address/info/:addressId'
export const EDIT_USER_ADDRESS_MAP_ROUTE = PURCHASE_ROUTE + '/user/address/info/map/:addressId'

// CHECKOUT
export const CHECKOUT_ROUTE = PURCHASE_ROUTE + '/checkout'
export const CHECKOUT_FINAL_ROUTE = PURCHASE_ROUTE + '/checkout/final'
export const CHECKOUT_PAYMENT_ROUTE = PURCHASE_ROUTE + '/checkout/payment'

// SETTINGS
export const SETTINGS_ROUTE = '/settings'

// DELIVERY
export const DELIVERY_ROUTE = '/delivery'
export const DELIVERY_COURIERS_LAYOUT_ROUTE = DELIVERY_ROUTE + '/couriers'
export const DELIVERY_COURIERS_WEEKLY_PLANS_PAGE_ROUTE =
  DELIVERY_COURIERS_LAYOUT_ROUTE + '/delivery-chains/:deliveryChainId/customers'
export const DELIVERY_COURIERS_DETAILS_ROUTE =
  DELIVERY_COURIERS_LAYOUT_ROUTE + '/details/:courierId'

// Account
export const ACCOUNT_ROUTE = PURCHASE_ROUTE + '/account'
export const ACCOUNT_MENU_ROUTE = ACCOUNT_ROUTE + '/menu'
export const ACCOUNT_PROFILE_ROUTE = ACCOUNT_ROUTE + '/profile'
export const ACCOUNT_PROFILE_FORGOT_PASSWORD_ROUTE = ACCOUNT_PROFILE_ROUTE + '/forgot-password'
export const ACCOUNT_PROFILE_FORGOT_PASSWORD_CONFIRM_ROUTE =
  ACCOUNT_PROFILE_FORGOT_PASSWORD_ROUTE + '/confirm'
export const ACCOUNT_PROFILE_FORGOT_PASSWORD_PASSWORD_ROUTE =
  ACCOUNT_PROFILE_FORGOT_PASSWORD_ROUTE + '/make-password'
export const ACCOUNT_RATING_ROUTE = ACCOUNT_ROUTE + '/rating'
export const ACCOUNT_TRANSACTION_LIST_ROUTE = ACCOUNT_ROUTE + '/transaction_list'

// Order History
export const ORDER_HISTORY_ROUTE = '/order-history'
export const ORDER_HISTORY_DETAILS_ROUTE = ORDER_HISTORY_ROUTE + '/order-details/:subscriptionId'

export const SUPPORT_ROUTE = '/support'
export const SUPPORT_FAQ_ROUTE = SUPPORT_ROUTE + '/faq'
export const SUPPORT_ABOUT_US_ROUTE = SUPPORT_ROUTE + '/about-us'
export const SUPPORT_TICKETS_ROUTE = SUPPORT_ROUTE + '/tickets'
