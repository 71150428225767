import {lazy} from 'react'
import {Navigate, useRoutes} from 'react-router-dom'

import {
  LOGIN_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  FORGOT_PASSWORD_SUCCESS_ROUTE,
  FORGOT_PASSWORD_CONFIRM_ROUTE,
  FORGOT_PASSWORD_PASSWORD_ROUTE,
  NOT_FOUND_ROUTE,

  // protected
  ACCOUNT_ADDRESS_ROUTE,
  USER_ADDRESS_ROUTE,
  USER_ADDRESS_INFO_ROUTE,
  USER_ADDRESS_MAP_ROUTE,
  EDIT_USER_ADDRESS_INFO_ROUTE,
  EDIT_USER_ADDRESS_MAP_ROUTE,
  REGISTER_ROUTE,
  REGISTER_CODE_ROUTE,
  REGISTER_PASSWORD_ROUTE,
  NEW_ORDER_ROUTE,
  ACCOUNT_ROUTE,
  ACCOUNT_MENU_ROUTE,
  ACCOUNT_PROFILE_ROUTE,
  ACCOUNT_PROFILE_FORGOT_PASSWORD_ROUTE,
  ACCOUNT_PROFILE_FORGOT_PASSWORD_CONFIRM_ROUTE,
  ACCOUNT_PROFILE_FORGOT_PASSWORD_PASSWORD_ROUTE,
  ACCOUNT_RATING_ROUTE,
  ACCOUNT_TRANSACTION_LIST_ROUTE,
  NEW_CUSTOM_BASKET_ROUTE,
  INTRO_ROUTE,
  CHECKOUT_ROUTE,
  PURCHASE_ROUTE,
  BASKETS_ROUTE,
  ORDER_HISTORY_DETAILS_ROUTE,
  ORDER_HISTORY_ROUTE,
  SUPPORT_ROUTE,
  SUPPORT_FAQ_ROUTE,
  SUPPORT_ABOUT_US_ROUTE,
  SUPPORT_TICKETS_ROUTE,
  CHECKOUT_FINAL_ROUTE,
  CHECKOUT_PAYMENT_ROUTE,
} from 'constants/routes'

import ProtectedRoute from './ProtectedRoute'
import PublicRoute from './PublicRoute'

// public
import PublicLayout from 'pages/public'
const IntroPage = lazy(() => import('pages/public/Intro'))

const RegisterLayout = lazy(() => import('pages/public/Register'))
const RegisterPage = lazy(() => import('pages/public/Register/main'))
const RegisterCodePage = lazy(() => import('pages/public/Register/confirm'))
const RegisterPasswordPage = lazy(() => import('pages/public/Register/password'))

const SignInPage = lazy(() => import('pages/public/SignIn'))

const ForgotPasswordLayout = lazy(() => import('pages/public/ForgotPassword'))
const ForgotPasswordPage = lazy(() => import('pages/public/ForgotPassword/main'))
const ForgotPasswordConfirmPage = lazy(() => import('pages/public/ForgotPassword/confirm'))
const ForgotPasswordPasswordPage = lazy(() => import('pages/public/ForgotPassword/password'))
const ForgotPasswordSuccessPage = lazy(() => import('pages/public/ForgotPassword/success'))

const ErrorPage = lazy(() => import('pages/public/Error'))

// protected
import ProtectedLayout from 'pages/protected'
const Purchase = lazy(() => import('pages/protected/Purchase'))
const OrderHistoryLayout = lazy(() => import('pages/protected/OrderHistory'))
const SupportLayout = lazy(() => import('pages/protected/Support'))
const OrderDetails = lazy(() => import('pages/protected/OrderHistory/OrderDetails'))
const FAQPage = lazy(() => import('pages/protected/Support/FAQ'))
const AboutUsPage = lazy(() => import('pages/protected/Support/AboutUs'))
const TicketsPage = lazy(() => import('pages/protected/Support/Tickets'))
const OrderHistoryList = lazy(() => import('pages/protected/OrderHistory/OrderHistoryLIst'))
const AddressLayout = lazy(() => import('pages/protected/Address/Account'))
const UserAddressLayout = lazy(() => import('pages/protected/Address/User'))
const UserAddressInfoLayout = lazy(() => import('pages/protected/Address/User/Info'))
const UserAddressMapLayout = lazy(() => import('pages/protected/Address/User/Map'))
const CheckoutPage = lazy(() => import('pages/protected/Purchase/CheckoutPage'))
const CheckoutCheckPage = lazy(() => import('pages/protected/Purchase/CheckoutPage/main'))
const CheckoutFinalPage = lazy(() => import('pages/protected/Purchase/CheckoutPage/final'))
const CheckoutPaymentPage = lazy(() => import('pages/protected/Purchase/CheckoutPage/payment'))
const NewOrderPage = lazy(() => import('pages/protected/Purchase/NewOrderPage'))
const AddNewCustomBasket = lazy(() => import('pages/protected/Purchase/AddNewCustomBasket'))
const BasketsPage = lazy(() => import('pages/protected/Purchase/BasketsPage'))
const AccountLayout = lazy(() => import('pages/protected/Account'))
const AccountMenuLayout = lazy(() => import('pages/protected/Account/Menu'))
const AccountProfileLayout = lazy(() => import('pages/protected/Account/Profile'))
const AccountRatingLayout = lazy(() => import('pages/protected/Account/Rating'))
const AccountTransactionListLayout = lazy(() => import('pages/protected/Account/TransactionList'))
const AccountProfileForgotPasswordLayout = lazy(
  () => import('pages/protected/Account/Profile/ForgotPassword')
)
const AccountProfileForgotPasswordConfirmPage = lazy(
  () => import('pages/protected/Account/Profile/ForgotPassword/confirm')
)
const AccountProfileForgotPasswordPasswordPage = lazy(
  () => import('pages/protected/Account/Profile/ForgotPassword/password')
)

export const Routes = () => {
  return useRoutes([
    {
      path: '/',
      element: <ProtectedRoute />,
      children: [
        {
          path: '/',
          element: <ProtectedLayout />,
          children: [
            {
              path: SUPPORT_ROUTE,
              element: <SupportLayout />,
              children: [
                {
                  path: SUPPORT_FAQ_ROUTE,
                  element: <FAQPage />,
                },
                {
                  path: SUPPORT_ABOUT_US_ROUTE,
                  element: <AboutUsPage />,
                },
                {
                  path: SUPPORT_TICKETS_ROUTE,
                  element: <TicketsPage />,
                },
              ],
            },

            // {
            //   path: DASHBOARD_ROUTE,
            //   element: <DashboardLayout />,
            // },
            {
              path: PURCHASE_ROUTE,
              element: <Purchase />,
              children: [
                {
                  path: NEW_ORDER_ROUTE,
                  element: <NewOrderPage />,
                },
                {
                  path: NEW_CUSTOM_BASKET_ROUTE,
                  element: <AddNewCustomBasket />,
                },
                {
                  path: BASKETS_ROUTE,
                  element: <BasketsPage />,
                },
                {
                  path: CHECKOUT_ROUTE,
                  element: <CheckoutPage />,
                  children: [
                    {
                      path: CHECKOUT_ROUTE,
                      element: <CheckoutCheckPage />,
                    },
                    {
                      path: CHECKOUT_FINAL_ROUTE,
                      element: <CheckoutFinalPage />,
                    },
                    {
                      path: CHECKOUT_PAYMENT_ROUTE,
                      element: <CheckoutPaymentPage />,
                    },
                  ],
                },
                {
                  path: ACCOUNT_ADDRESS_ROUTE,
                  element: <AddressLayout />,
                },
                {
                  path: USER_ADDRESS_ROUTE,
                  element: <UserAddressLayout />,
                  children: [
                    {
                      path: USER_ADDRESS_INFO_ROUTE,
                      element: <UserAddressInfoLayout />,
                    },
                    {
                      path: USER_ADDRESS_MAP_ROUTE,
                      element: <UserAddressMapLayout />,
                    },
                    {
                      path: EDIT_USER_ADDRESS_INFO_ROUTE,
                      element: <UserAddressInfoLayout />,
                    },
                    {
                      path: EDIT_USER_ADDRESS_MAP_ROUTE,
                      element: <UserAddressMapLayout />,
                    },
                  ],
                },
                {
                  path: ACCOUNT_ROUTE,
                  element: <AccountLayout />,
                  children: [
                    {
                      path: ACCOUNT_MENU_ROUTE,
                      element: <AccountMenuLayout />,
                    },
                    {
                      path: ACCOUNT_PROFILE_ROUTE,
                      element: <AccountProfileLayout />,
                    },
                    {
                      path: ACCOUNT_PROFILE_FORGOT_PASSWORD_ROUTE,
                      element: <AccountProfileForgotPasswordLayout />,
                      children: [
                        {
                          path: ACCOUNT_PROFILE_FORGOT_PASSWORD_CONFIRM_ROUTE,
                          element: <AccountProfileForgotPasswordConfirmPage />,
                        },
                        {
                          path: ACCOUNT_PROFILE_FORGOT_PASSWORD_PASSWORD_ROUTE,
                          element: <AccountProfileForgotPasswordPasswordPage />,
                        },
                      ],
                    },
                    {
                      path: ACCOUNT_RATING_ROUTE,
                      element: <AccountRatingLayout />,
                    },
                    {
                      path: ACCOUNT_TRANSACTION_LIST_ROUTE,
                      element: <AccountTransactionListLayout />,
                    },
                  ],
                },
                {
                  path: PURCHASE_ROUTE,
                  element: <Navigate to={NEW_ORDER_ROUTE} />,
                },
              ],
            },
            {
              path: ORDER_HISTORY_ROUTE,
              element: <OrderHistoryLayout />,
              children: [
                {
                  index: true,
                  element: <OrderHistoryList />,
                },
                {
                  path: ORDER_HISTORY_DETAILS_ROUTE,
                  element: <OrderDetails />,
                },
              ],
            },
            {
              path: '/',
              element: <Navigate to={PURCHASE_ROUTE} />,
            },
          ],
        },
      ],
    },
    {
      path: '/',
      element: <PublicRoute />,
      children: [
        {
          path: '/',
          element: <PublicLayout />,
          children: [
            {
              path: INTRO_ROUTE,
              element: <Navigate to={REGISTER_ROUTE} />,
            },
            {
              path: REGISTER_ROUTE,
              element: <RegisterLayout />,
              children: [
                {
                  path: REGISTER_ROUTE,
                  element: <RegisterPage />,
                },
                {
                  path: REGISTER_CODE_ROUTE,
                  element: <RegisterCodePage />,
                },
                {
                  path: REGISTER_PASSWORD_ROUTE,
                  element: <RegisterPasswordPage />,
                },
              ],
            },
            {
              path: LOGIN_ROUTE,
              element: <SignInPage />,
            },
            {
              path: FORGOT_PASSWORD_ROUTE,
              element: <ForgotPasswordLayout />,
              children: [
                {
                  path: FORGOT_PASSWORD_ROUTE,
                  element: <ForgotPasswordPage />,
                },
                {
                  path: FORGOT_PASSWORD_SUCCESS_ROUTE,
                  element: <ForgotPasswordSuccessPage />,
                },
                {
                  path: FORGOT_PASSWORD_CONFIRM_ROUTE,
                  element: <ForgotPasswordConfirmPage />,
                },
                {
                  path: FORGOT_PASSWORD_PASSWORD_ROUTE,
                  element: <ForgotPasswordPasswordPage />,
                },
              ],
            },
            {
              path: '/',
              element: <Navigate to={LOGIN_ROUTE} />,
            },
          ],
        },
      ],
    },
    {
      path: NOT_FOUND_ROUTE,
      element: <ErrorPage />,
    },
  ])
}
